import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "./../Config/ApiSettings";

class PodcastListHome extends React.Component {
    // State will apply to the posts object which is set to loading by default
    state = {
        podcasts: [],
        isLoading: true,
        errors: null,
    };
    // Now we're going to make a request for data using axios
    getPosts() {
        axios
            // This is where the data is hosted
            .get(`${apiUrl}/podcasty-video/?api=true&sort=-priority|-created`, {
                headers: {
                    // 'Content-Type': 'application/json'
                },
            })
            // Once we get a response and store data, let's change the loading state
            .then((response) => {
                this.setState({
                    podcasts: response.data,
                    isLoading: false,
                });
            })
            // If we catch any errors connecting, let's update accordingly
            .catch((error) => this.setState({ error, isLoading: false }));
    }
    // Let's our app know we're ready to render the data
    componentDidMount() {
        this.getPosts();
    }
    // Putting that data to use
    render() {
        const { isLoading, podcasts } = this.state;

        return (
            <React.Fragment>
                <div className="podcasts-section">
                    <div className="dynamic-boxes">
                        <div className="box-1"> </div>
                        <div className="box-2"> </div>
                        <div className="box-3"> </div>
                    </div>
                    <h2 className="">Podcasty / Video</h2>
                    <div className="podcast-list">
                        {!isLoading ? (
                            podcasts.slice(0, 8).map((podcast) => {
                                const { id, title, podcast_image, url, podcast_categories, created } = podcast;
                                // let timestamp = Number(new Date( created * 1000 ));
                                //   let date = new Date(timestamp).toLocaleDateString('pl-PL', {
                                //     month: 'numeric',
                                //     day: 'numeric',
                                //     year: 'numeric',
                                // });
                                let date = new Date(created).toLocaleDateString("pl-PL", {
                                    month: "numeric",
                                    day: "numeric",
                                    year: "numeric",
                                });
                                return (
                                    <div className="podcast-item" key={id}>
                                        <span className="podcast-date">{date}</span>
                                        <Link to={url}>
                                            <img src={podcast_image} className="podcast--image" alt="" />
                                        </Link>
                                        <h3 className="podcast--title">
                                            <Link to={url}>{title}</Link>
                                        </h3>
                                        <span className="podcasts-metadata">
                                            <span className="podcast--tags">
                                                <Link to={podcast_categories[0].path}>
                                                    #{podcast_categories[0].title}
                                                </Link>
                                            </span>
                                            <span className="podcast--category">#Podcasty</span>
                                        </span>
                                    </div>
                                );
                            })
                        ) : (
                            <p>Wczytywanie...</p>
                        )}
                    </div>
                    {!isLoading ? (
                        <div className="podcast-more-button">
                            <Link to="/podcasty-video" className="podcast-more--url">
                                Więcej
                            </Link>
                        </div>
                    ) : (
                        <span></span>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default PodcastListHome;
